import React, {useState, useEffect} from 'react';
import './App.css';
import NoSession from './nosession'
import dispatcher from './dispatcher'
import consts from './constants'
import TopBar from './topbar'
import Tabs from './tabs'
import util from './utils'

function App() {
  const [session,setSession] = useState(!util.isEmpty(localStorage.getItem('X-Poslog-ShoppingId')))
  const [shopId,setShopId] = useState(null)
  const [tab,setTab] = useState(consts.SHOP)

  useEffect(() => {
    let token = dispatcher.register((data) => {
      if(data.type == consts.SESSION) {
        setSession(data.val)
      }
      if(data.type == consts.TABS) {
        setTab(data.val)
      }
    })

    return ()=>{
      dispatcher.unregister(token)
    }
  },[])
  if(session) {
    return (
      <div>
        <TopBar/>
        <Tabs tab={tab}/>
      </div>
    )
  }
  else {
    return (
      <NoSession/>
    )
  }
}

export default App;
