let consts = {

    // dispatches
    TABS: 1,
    SESSION: 2,
    
    //Tabs
    SHOP: 1,
    ERROR: 2,
    ORDER_VIEW: 3,
    ORDER_CONFIRM: 4,
    ORDER_LIST: 5
}

export default consts;