import React, { useState } from 'react'
import dispatcher from './dispatcher'
import consts from './constants'
import Http from './http'

function login(loginId,customerId,setError) {
    let http = Http.request('/auth/shop',JSON.stringify({loginId: loginId,customerId: customerId}),'POST')
    http.then(response => {
        localStorage.setItem('X-Poslog-ShopId',response.shopId)
        localStorage.setItem('X-Poslog-ShoppingId',response.shoppingId)
        localStorage.setItem('X-Poslog-CustomerId',customerId)
        dispatcher.dispatch({type: consts.SESSION, val: true})
        dispatcher.dispatch({type: consts.TABS, val: consts.SHOP});
    })
    http.catch(err => {
        setError('Login Failed!')
    })
    
}

function NoSession() {
    const [loginId,setloginId] = useState(null)
    const [customerId,setCUstomerId] = useState(null)
    const [error,setError] = useState(null)

    return (
        <form onSubmit={(e)=>{e.preventDefault();login(loginId,customerId,setError)}}>
        <div class="w3-center w3-text-deep-orange">
            <div class="w3-padding w3-deep-orange w3-xxlarge w3-baloo w3-margin-bottom w3-card-2">
                Retail+
            </div>
            <div class="w3-jumbo w3-fitscreen w3-content">
                <table class="w3-table w3-centered">
                <tr>
                    <td><i class="fas fa-store"></i></td>
                    <td><i class="fas fa-long-arrow-alt-right"></i></td>
                    <td><i class="fas fa-shipping-fast"></i></td>
                    <td><i class="fas fa-long-arrow-alt-right"></i></td>
                    <td><i class="fas fa-house-user"></i></td>
                </tr>
                </table>
            </div>
            <div class="w3-content-small w3-padding">
                Phone/E-Mail
                <input class="w3-input w3-margin-bottom w3-border w3-round w3-border-deep-orange" value={loginId} onChange={(e)=>{setloginId(e.target.value)}}/>
                Customer Id
                <input class="w3-input w3-margin-bottom w3-border w3-round w3-border-deep-orange" value={customerId} onChange={(e)=>{setCUstomerId(e.target.value)}}/>
                <button class="w3-btn w3-deep-orange w3-round w3-ripple" type="submit">Go</button>
            </div>
            <label class="w3-baloo w3-large">{error}</label>
            <div class="w3-padding">
                &nbsp;
            </div>
            {/*<div class="w3-padding w3-text-deep-orange w3-xxlarge w3-baloo w3-margin-top">
                Shops you Trust
            </div>*/}
            <div class="w3-padding w3-bottom w3-deep-orange w3-tiny">
                Your favorite store, right at your finger tip!
            </div>
        </div>
        </form>
    )
}
  
  export default NoSession;
  