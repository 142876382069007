import React, { useState, useEffect, useReducer } from 'react'
import dispatcher from './dispatcher'
import consts from './constants'
import Http from './http'
import util from './utils'
import { placeholder } from '@babel/types';

let stocks = []
let costs = []
let items = []
let order = []
//let stockRetrieved = false;

let initState = {items: 0, cost: 0};

function reducer(state,action) {
    
    let obj = {};
    let totCost = costs.reduce((tot,num) => {return (tot + num)})
    if(action.type == 'SELECT') {
        //obj = Object.assign(state,{items: state.items + 1})
        //console.log(obj)
        //return(obj)
        //return(Object.assign(state,{items: state.items + 1}))
        return({items: state.items + 1, cost: totCost})
    }
    if(action.type == 'UNSELECT') {
        //obj = Object.assign(state,{items: state.items - 1})
        //console.log(obj)
        //return(obj)
        //return(Object.assign(state,{items: state.items - 1}))
        return({items: state.items - 1, cost: totCost})
    }
    if(action.type == 'QTY') {
        return({items: state.items, cost: totCost})
    }
}

function DisplayItems(props) {
    const [className,setClassName] = useState('w3-text-deep-orange')
    const [qty,setQty] = useState(1)
    let cost = util.isEmpty(stocks[props.index].mrp) ? 0 : stocks[props.index].mrp
    let discount = stocks[props.index].discount
    if(!util.isEmpty(discount)) {
        cost = cost * (1 - discount/100)
    }

    const costFn = (e) => {
        let num = e.target.value
        //num = num.replace(/[^0-9.]/g,'')
        if(num.substring(num.length-2) == '.') {
            setQty(num)
            return
        }
        if(util.isEmpty(num)) {
            setQty('')
            return
        }
        if(isNaN(num)) {
            return
        }
        let action = '';

        costs[props.index] = cost * num
        items[props.index] = num
        if(num != qty) {
            action = 'QTY'
            /*if(num == 0) {
                setQty('')
            }*/
            props.dispatch({type: action})
        }
        setQty(num)
    }

    const click = () => {
        let color, action;
        if(className == 'w3-text-deep-orange') {
            color = 'w3-deep-orange'
            action = 'SELECT'
            costs[props.index] = cost;
            items[props.index] = 1
        }
        else {
            color = 'w3-text-deep-orange'
            action = 'UNSELECT'
            costs[props.index] = 0;
            items[props.index] = 0
            setQty(1)
        }
        setClassName(color)
        props.dispatch({type: action, index: props.index})
    }
    if(stocks[props.index].no_stock) {
        return(
            <div class="w3-padding w3-quarter w3-center">
                <div class={"w3-border w3-border-black w3-round-large w3-padding w3-baloo w3-light-grey"}>
                    <img src={'/images/' + localStorage.getItem('X-Poslog-ShopId') + '/' + stocks[props.index].sku} class="w3-full-width"/>
                    <div>{stocks[props.index].name}<br/>
                    @ Rs {cost.toFixed(2)}
                    <br/>Out Of Stock</div>
                </div>
            </div>
        )            
    }
    else {
        return(
            <div class="w3-padding w3-quarter w3-center">
                <div class={"w3-border w3-border-deep-orange w3-round-large w3-padding w3-baloo w3-hover-card w3-cursor-pointer " + className}>
                    <img src={'/images/' + localStorage.getItem('X-Poslog-ShopId') + '/' + stocks[props.index].sku} class="w3-full-width" onClick={click}/>
                    <div onClick={click}>{stocks[props.index].name}<br/>
                    @ Rs {cost.toFixed(2)}</div>
                    {
                        className == 'w3-deep-orange' ? <div>Qty <input class="w3-input" onChange={costFn} value={qty}/></div> : null
                    }
                </div>
            </div>
        )
    }
}

function Shop() {

    const [loop,setLoop] = useState([])
    //const [isLoaded,setLoaded] = useState(false)
    const [state,dispatch] = useReducer(reducer,initState)
    const [shop,setShop] = useState({
        name: null,
        gstin: null,
        phone: null,
        pincode: null,
        city: null,
        district: null,
        state: null
    })
    const [progress,setProgress] = useState('block')

    const func = (data) => {
        dispatch(data)
    }
    useEffect(() => {
        //if(util.isEmpty(shop.name)) {
            let http1 = Http.request('search/establishment',JSON.stringify({}),'POST')
            http1.then(response => {
                setShop({name: response[0].name,
                    gstin: response[0].gstin,
                    phone: response[0].phone,
                    pincode: response[0].pincode,
                    city: response[0].city,
                    district: response[0].district,
                    state: response[0].state})
                //setLoaded(true)
            })
            http1.catch(err => {
                dispatcher.dispatch({type: consts.ERROR,val: err})
            })    
        //}
        /*if(isLoaded) {
            return
        }*/
        stocks.length = 0
        costs.length = 0
        items.length = 0
        let http2 = Http.request('/data/catalogue/stock',null,'GET')
        http2.then(response => {
            setProgress('none')
            let arr = []
            for(let i = 0; i < response.length; i++) {
                stocks.push(response[i]);
                arr.push(i)
                costs.push(0)
                items.push(0)
    //            setItems(items + 1);
            }
            setLoop(arr.slice())
            //stockRetrieved = true;
        })
        http2.catch(err => {
            dispatcher.dispatch({type: consts.ERROR,val: err})
        })
    },[])

    const placeOrder = () => {
        order.length = 0;
        for(let i = 0; i < items.length; i++) {
            if(items[i] > 0) {
                order.push({
                    item: stocks[i].name,
                    rate: stocks[i].mrp,
                    qty: items[i],
                    cost: costs[i],
                    sku: stocks[i].sku,
                    tax: stocks[i].tax,
                    discount: stocks[i].discount
                })    
            }
        }
        dispatcher.dispatch({type: consts.TABS, val: consts.ORDER_CONFIRM})
    }
    return(
        <div>
            <div class="w3-margin-top w3-margin-bottom">
                <div class="w3-center w3-baloo w3-text-grey">
                    <div class="w3-xlarge">{shop.name}</div>
                    <div class="w3-tiny">Phone: {shop.phone}, Pincode: {shop.pincode}, {shop.city}, {shop.district}, {shop.state}</div>
                </div>
                {loop.map((val,i) => (
                    <DisplayItems index={i} dispatch={func}/>
                    //DisplayItems({index: i})    
                ))}
            </div>
            <div class="w3-margin w3-col l12">&nbsp;</div>
            <div class="w3-display-container w3-padding" style={{display:progress}}>
                <div className="w3-loader w3-display-middle"/>
            </div>
            <div class="w3-bottom w3-topbar w3-border-deep-orange w3-white w3-fitscreen">    
                <table class="w3-table w3-centered">
                    <tr>
                        <td class="w3-baloo">Items : {state.items}</td>
                        <td class="w3-baloo">Cost : Rs {state.cost.toFixed(2)}</td>
                        <td><button className="w3-btn w3-ripple w3-round-large w3-deep-orange w3-small" onClick={placeOrder}>Place Order</button></td>
                    </tr>
                </table>
            </div>
        </div>
    )
}

export default Shop
export {order, costs}