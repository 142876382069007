import React, { useState, useEffect } from 'react'
import dispatcher from './dispatcher'
import consts from './constants'
import Http from './http'
import util from './utils'
import moment from 'moment'

function listOrder(val,index) {
    let color = "w3-text-dark-grey"
    switch(val.status) {
        case "Accepted":
            color = "w3-text-purple w3-hover-purple"
            break
        case "Dispatched":
            color = "w3-text-indigo w3-hover-indigo"
            break
        case "Delivered":
            color = "w3-text-teal w3-hover-teal"
            break
        case "Cancelled":
            color = "w3-text-red w3-hover-red"
            break
        default:
            color = "w3-text-dark-grey w3-hover-dark-grey"
            break
    }
    return(
        <tr class={color + ' w3-cursor-pointer'} onClick={() => {
            util.setOrderId(val.id)
            dispatcher.dispatch({type: consts.TABS, val: consts.ORDER_VIEW})
        }}>
            <td>{val.id}</td>
            <td>{val.date}</td>
            <td>{val.status}</td>
        </tr>
    )
}

function OrderList() {
    const [orders,setOrders] = useState([])
    const [progress,setProgress] = useState('none')

    useEffect(() => {
        //if(orders.length == 0) {
            let customerId = localStorage.getItem('X-Poslog-CustomerId')
            setProgress('block')
            let http = Http.request('/data/catalogue/orders?customer_id=' + customerId,null,'GET')
            http.then(response => {
                let arr = []
                setProgress('none')
                for(let i = 0; i < response.length; i++) {
                    arr.push({id: response[i].id, status: response[i].status, date: moment(response[i].order_date).format('MMM Do YYYY h:mm:ss a')})
                }
                setOrders(arr.slice())
            })
            http.catch(err => {
                setProgress('none')
                util.setError(err)
                dispatcher.dispatch({type: consts.TABS, val: consts.ERROR})
            })
        //}
    },[])
    return(
        <div>
            <div class="w3-padding w3-content w3-fitscreen w3-center w3-baloo">
                <span class="w3-border-bottom w3-border-deep-orange w3-text-deep-orange w3-large">&nbsp;Your Orders&nbsp;</span>
                <table class="w3-table w3-centered w3-margin-top">
                    <tr class="w3-bottombar w3-border-deep-orange">
                        <td><b>Order Id</b></td>
                        <td><b>Order Date</b></td>
                        <td><b>Status</b></td>
                    </tr>
                    {orders.map(listOrder)}
                </table>
                
            </div>
            <div class="w3-display-container w3-padding" style={{display:progress}}>
                <div className="w3-loader w3-display-middle"/>
            </div>
        </div>
    )
}

export default OrderList