import React, { useState } from 'react';
import util from './utils'

function Error() {
    return(
        <div className="w3-center">
            <div className="w3-text-deep-orange w3-baloo">
                <p>&nbsp;</p>
                <p><i className="fa fa-exclamation-circle w3-jumbo"/></p>
                <p><label className="w3-jumbo">{util.getError()}</label></p>
            </div>
        </div>
    )
}

export default Error