import React, {useState} from 'react';
import dispatcher from './dispatcher';
import consts from './constants'


function activeTab(val) {
    let data = {type: consts.TABS, val: val};
    dispatcher.dispatch(data);
}

function logout() {
    localStorage.removeItem('X-Poslog-ShopId')
    localStorage.removeItem('X-Poslog-ShoppingId')
    localStorage.removeItem('X-Poslog-CustomerId')
    dispatcher.dispatch({type: consts.SESSION, val: false})
}

function topBar() {
    return(
        <div className="w3-container w3-deep-orange w3-card-2 w3-fitscreen w3-small-flex">
            <span>
                <a className="w3-hover-white-o w3-text-white w3-xlarge w3-padding-tiny w3-round" href="javascript:void(0)" onClick={()=>activeTab(consts.SHOP)}>
                    <i className="fa fa-home"></i>
                </a>
            </span>                        
            <ul className="w3-navbar w3-right">
                <li>
                    <a className="w3-hover-white-o" href="javascript:void(0)" onClick={()=>activeTab(consts.ORDER_LIST)}>Orders</a>
                </li>   
                <li><a className="w3-hover-white-o" href="javascript:void(0)" onClick={() => logout()}><i className="fa fa-power-off"/></a></li>                 
            </ul>
        </div>
    )
}

export default topBar