import util from './utils';
import consts from './constants';
import dispatcher from './dispatcher';

function processHeader() {
  if(this.readyState == consts.HEADERS_RECEIVED) {
    util.setAccessCode(this.getResponseHeader('X-Poslog-AccessCode'));
  }
}

class Http {

    request(url,data,method,type) {
    
      return new Promise(function(resolve, reject) {
      // Standard XHR to load an image
      var request = new XMLHttpRequest();
      request.open(method, url);

      request.setRequestHeader('X-Poslog-ShoppingId',localStorage.getItem('X-Poslog-ShoppingId'));
      request.setRequestHeader('X-Poslog-ShopId',localStorage.getItem('X-Poslog-ShopId'));
      if(util.isEmpty(type)) {
        request.setRequestHeader('Content-Type', 'application/json');
        request.responseType = 'json';
      }
      else {
        request.setRequestHeader('Content-Type', type);
        request.responseType = 'text';
      }

      // Send the request
      if(util.isEmpty(data)) {
        request.send();
      }
      else {
        request.send(data);
//        console.log(data);
      }
      // When the request loads, check whether it was successful
      request.onload = function() {
        if (request.status === 200 || request.status === 201) {
          
        // If successful, resolve the promise by passing back the request response
          resolve(request.response);
        } else if(request.status === 401) {
//          dispatcher.dispatch({type: consts.TAB_ACTION, category: consts.SESSION, val: false});
          util.setError('Session Expired. Login Again')
          localStorage.removeItem('X-Poslog-ShoppingId');
          localStorage.removeItem('X-Poslog-ShopId');
          reject('Authentication Error!');
          dispatcher.dispatch({type: consts.TABS, val: consts.ERROR});
        } else if(request.status === 412) {
          reject((request.response).toString());
        } else if(request.status === 500 || request.status === 501){
          // If it fails, reject the promise with a error message
          util.setError('Application Error')
          dispatcher.dispatch({type: consts.TABS, val: consts.ERROR});
          reject('Application Error!');
        }
        else {
          util.setError('Data Not Found!')
          reject('Data Not Found!')
        }
      };
      request.onerror = function() {
      // Also deal with the case when the entire request fails to begin with
      // This is probably a network error, so reject the promise with an appropriate message
        util.setError('Network Error')
        dispatcher.dispatch({type: consts.TAB_ACTION, val: consts.ERROR});
        reject('Network Error');
      };      
    });
  }
}

export default new Http();