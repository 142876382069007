import React, { useState, useEffect } from 'react'
import dispatcher from './dispatcher'
import consts from './constants'
import Http from './http'
import util from './utils'


function orderItems(val,index) {
    return(
        <tr class="w3-text-dark-grey">
            <td>{val.item}</td>
            <td>{(val.rate).toFixed(2)}</td>
            <td>{val.qty}</td>
            <td>{val.discount}</td>
            <td>{(val.cost).toFixed(2)}</td>
        </tr>
    )
}

function OrderView(props) {

    const [status,setStatus] = useState('')
    const [color,setColor] = useState('')
    const [order,setOrder] = useState([])
    const [total,setTotal] = useState(0)
    const [msg,setMsg] = useState('')
    const [progress,setProgress] = useState('none')

    useEffect(() => {
        let orderId = util.getOrderId()
        let orderHttp = Http.request('/data/classes/orders/' + orderId,null,'GET')
        orderHttp.then((response) => {
            setStatus(response.status)
            switch(response.status) {
                case 'Submitted':
                    setColor('w3-text-dark-grey')
                    break
                case 'Accepted':
                    setColor('w3-text-purple')
                    break
                case 'Dispatched':
                    setColor('w3-text-indigo')
                    break
                case 'Delivered':
                    setColor('w3-text-teal')
                    break
                case 'Cancelled':
                    setColor('w3-text-red')
                    break
            }   
        })
        orderHttp.catch(err => {
            util.setError("Error retreiving Data!")
            dispatcher.dispatch({type: consts.TABS, val: consts.ERROR})
        })
        let orderItemHttp = Http.request('data/lists/order_items?order_id='.concat(orderId),null,'GET')
        orderItemHttp.then((response) => {
            let arr = []
            let tot = 0
            for(let i = 0; i < response.length; i++) {
                arr.push(response[i])
                tot += response[i].cost
            }
            setOrder(arr.slice())
            setTotal(tot)
        })
        orderItemHttp.catch(err => {
            util.setError("Error retreiving Data!")
            dispatcher.dispatch({type: consts.TABS, val: consts.ERROR})
        })    
    },[])

    const cancelOrder = async function() {
        try {
            let res = await Http.request('/data/classes/orders/' + util.getOrderId(),null,'GET')
            if(res.status != 'Submitted') {
                setMsg('Sorry! This order cannot be cancelled as its already accepted by the shop.')
                //setStatus(res.status)
            }
            else {
                setProgress('block')
                res = await Http.request('/data/classes/orders/' + util.getOrderId(),JSON.stringify({'status': 'Cancelled'}),'PUT')
                setStatus('Cancelled')
                await Http.request('notify/email/order/cancel/' + util.getOrderId(),null,'GET')
                setMsg('')
                setProgress('none')
            }
        }
        catch(err) {
            setProgress('none')
            util.setError('Oops! Something went wrong!')
            dispatcher.dispatch({type: consts.TABS, val: consts.ERROR})
        }
    }

    return(
        <div class="w3-padding w3-center w3-baloo  w3-text-dark-grey">
            <span class="w3-xlarge w3-text-deep-orange w3-border-bottom w3-border-deep-orange">Order Details</span>
            <div class="w3-margin-top">
                Order Id : {util.getOrderId()}<br/>
                Status : <label class={color}>{status}</label>
            </div>
            <div class="w3-fitscreen">
                <table class="w3-table w3-centered w3-border-deep-orange w3-padding w3-fitscreen">
                    <tr class="w3-bottombar w3-border-deep-orange">
                        <td>Item</td>
                        <td>Rate</td>
                        <td>Qty</td>
                        <td>Discount</td>
                        <td>Price</td>
                    </tr>
                    {order.map(orderItems)}
                    <tr class="w3-topbar w3-border-deep-orange">
                        <td></td><td></td><td></td>
                        <td>Total</td>
                        <td>{total.toFixed(2)}</td>
                    </tr>
                </table>
            </div>
            {status == 'Submitted' ? 
                <div class="w3-margin">
                    <button class="w3-btn w3-ripple w3-deep-orange w3-round-large" onClick={cancelOrder}>Cancel Order</button>
                    <br/><label class="w3-tiny w3-roboto">Order cannot be cancelled once it is accepted by us.</label>
                    <br/><label class="w3-text-red">{msg}</label>
                </div>
                 : null}
            <div class="w3-display-container w3-padding" style={{display:progress}}>
                <div className="w3-loader w3-display-middle"/>
            </div>
        </div>
    )
}

export default OrderView