import React, { useState } from 'react'
import Error from './error'
import consts from './constants'
import Shop from './shop'
import Order from './order'
import OrderView from './orderView'
import OrderList from './orderList'

function Tabs(props) {
    switch(props.tab) {
        case consts.SHOP :
            return(<Shop/>)
        case consts.ERROR :
            return(<Error/>)
        case consts.ORDER_CONFIRM :
            return(<Order/>)
        case consts.ORDER_VIEW :
            return(<OrderView/>)
        case consts.ORDER_LIST :
            return(<OrderList/>)
        default :
            return(<Shop/>)
    }
}

export default Tabs