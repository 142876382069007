import React, { useState, useEffect } from 'react'
import dispatcher from './dispatcher'
import consts from './constants'
import Http from './http'
import util from './utils'
import {order,costs} from './shop'


function orderItems(val,index) {
    return(
        <tr class="w3-text-dark-grey">
            <td>{val.item}</td>
            <td>{(val.rate).toFixed(2)}</td>
            <td>{val.qty}</td>
            <td>{val.discount}</td>
            <td>{(val.cost).toFixed(2)}</td>
        </tr>
    )
}

function Order(props) {

    const [customer,setCustomer] = useState({
        id: null,
        name: null,
        phone: null,
        email: null,
        location: null
    })
    const [error,setError] = useState({txt: '', show: 'w3-hide'})
    const [freeDelivery,setFreeDelivery] = useState(true)
    const [progress,setProgress] = useState('none')

    useEffect(() => {
        let http = Http.request('search/customers',JSON.stringify({shop_id: localStorage.getItem('X-Poslog-ShoppingId')}),'POST')
        http.then(response => {
            setCustomer({
                id: response[0].id,
                name: response[0].customer_name,
                phone: response[0].customer_number,
                email: response[0].customer_mail,
                location: response[0].customer_location
            })
        })
        http.catch(err => {
            util.setError(err)
            dispatcher.dispatch({type: consts.TABS,val: consts.ERROR})
        })
    },[])

/*    const changeAddress = (e) => {
        let http = Http.request('data/classes/customers/' + customer.id,JSON.stringify({customer_location: customer.location}),'PUT')
        http.then(response => {
            setError({txt: 'Address changed successfully ', show: 'w3-text-green'})
        })
        http.catch(err => setError({txt: 'Address Change Failed! ', show: 'w3-text-red'}))
    } */

    const confirmOrder = async () => {
        let postOrder;
        let payLoad = []
        try {
            setProgress('block')
            let obj = {customer_id: customer.id,free_delivery: freeDelivery}
            if(util.isEmpty(customer.location)) {
                setError({txt: 'Delivery Address is Mandatory', show: 'w3-text-red'})
                return
            }
            else {
                obj.delivery_address = customer.location
            }
            postOrder = await Http.request('data/classes/orders',JSON.stringify(obj),'POST');
            for(let i = 0; i < order.length; i++) {
                payLoad.push(Object.assign({},{order_id: postOrder.id,},order[i]))
            }
            await Http.request('data/lists/order_items',JSON.stringify(payLoad),'POST')
            util.setOrderId(postOrder.id)
            await Http.request('notify/email/order/' + util.getOrderId(),null,'GET')
            await Http.request('data/classes/customers/' + customer.id,JSON.stringify({customer_location: customer.location}),'PUT')
            setProgress('none')
            dispatcher.dispatch({type:consts.TABS, val: consts.ORDER_VIEW})
        }
        catch(err) {
            setError({txt: 'Transaction Failed! Please try again ', show: 'w3-text-red'})
            setProgress('none')
        }
    }
    return(
        <div class="w3-margin-bottom w3-baloo w3-padding">
            <div class="w3-center">
                <span class="w3-large w3-border-deep-orange w3-border-bottom w3-text-deep-orange">&nbsp;Order Confirmation&nbsp;</span>
            </div>
            <div class="w3-center w3-text-dark-grey w3-padding-top">
                &nbsp;Customer Id: {customer.id}, Name: {customer.name}, Phone: {customer.phone}, Email: {customer.email}&nbsp;
            </div>
            <div class="w3-fitscreen">
                <table class="w3-table w3-centered w3-border-deep-orange w3-padding w3-fitscreen">
                    <tr class="w3-bottombar w3-border-deep-orange">
                        <td>Item</td>
                        <td>Rate</td>
                        <td>Qty</td>
                        <td>Discount</td>
                        <td>Price</td>
                    </tr>
                    {order.map(orderItems)}
                    <tr class="w3-topbar w3-border-deep-orange">
                        <td></td><td></td><td></td>
                        <td>Total</td>
                        <td>{(costs.reduce((tot,num) => {return (tot + num)})).toFixed(2)}</td>
                    </tr>
                </table>
            </div>
            <div class="w3-roboto w3-tiny w3-center">* Delivery Charges may apply<br/>Payment Method : Cash on Delivery</div>
            Delivery Address:
            <div>
                <input class="w3-input w3-border w3-round-large" value={customer.location} onChange={(e)=>setCustomer({
                                        id: customer.id,
                                        name: customer.name,
                                        phone: customer.phone,
                                        email: customer.email,
                                        location: e.target.value
                                    })}/>&nbsp;
                {/*<button class="w3-btn w3-ripple w3-deep-orange w3-round-large w3-roboto" onClick={changeAddress}>Change</button>*/}
            </div>
            {/*<div class="w3-roboto w3-text-dark-grey w3-margin-top">Cancel Order if Delivery Charge applies? <input type="checkbox" onClick={(e) => setFreeDelivery(e.target.checked)}/></div>*/}
            <div class="w3-content-small w3-topbar w3-border-deep-orange w3-white w3-center w3-margin-top w3-center w3-padding-top w3-roboto">    
                <button class="w3-btn w3-ripple w3-deep-orange w3-round-large" onClick={() => dispatcher.dispatch({type: consts.TABS, val: consts.SHOP})}>Cancel</button>&nbsp;&nbsp;
                <button class="w3-btn w3-ripple w3-deep-orange w3-round-large" onClick={confirmOrder}>Confirm</button>
            </div>
            <div className={error.show + ' w3-white w3-animate-bottom w3-bottom-2 w3-padding'}>
                <span className="w3-right">
                    <label>{error.txt}</label>&nbsp;
                    <i className="fa fa-remove w3-text-black w3-ripple" onClick={() => setError({show: 'w3-hide'})}/>
                </span>
            </div>
            <div class="w3-display-container w3-padding" style={{display:progress}}>
                <div className="w3-loader w3-display-middle"/>
            </div>
        </div>
    )
}

export default Order