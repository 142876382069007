class Util {
    constructor() {
        this.error = null;
        this.accessCode = null;
        this.tenantId = null;
        this.userId = null;
        this.sessionId = null;
        this.saleProgress = false;
        this.custExist = false;
        this.orderId = null
    }
    isEmpty(val) {
        return (val === null || val === undefined || val === '');
    }
    setError(val) {
        this.error = val;
    }
    getError() {
        return this.error;
    }
    clearSearchObject(obj) {
        for(let key in obj) {
            delete obj[key];
        }
    }
    isEmptyObject(val) {
        return(JSON.stringify(val) == JSON.stringify({}));
    }
    isEmptyVals(obj) {
        let retVal = true;
        let val;
        for(let key in obj) {
            val = obj[key]
            if(!this.isEmpty(val)) {
                retVal = false;
                break;
            }
        }
        return retVal;
    }
    setSaleProgress(val) {
        this.saleProgress = val;
    }
    getSaleProgress() {
        return this.saleProgress;
    }
    setOrderId(val) {
        this.orderId = val
    }
    getOrderId() {
        return this.orderId;
    }
}

let util = new Util();
export default util;